
export const environment = {
  env: 'production',
  hideConsole: false,
  production: true,
  keyCloak: {
    urlAuthKeycloak: 'https://auth.test.elecate.net',
    realmKeycloak: 'elecate',
    clientId: 'academy-spa',
    scope: 'openid acr email offline_access profile roles web-origins',
    silentRenewUrl: 'https://auth.test.elecate.net/realms/elecate/protocol/openid-connect/auth/connect/token',
    redirectUrl: `${window.location.origin}/sign-in`, // domain or port of the app
    postLogoutRedirectUri: `${window.location.origin}/sign-out`, // main app
  },
  graphql: {
    academy_endpoint: 'https://academy.graphql.dev.enterprise.elecate.net/v1/graphql/',
    academy_endpoint_wss: 'wss://academy.graphql.dev.enterprise.elecate.net/v1/graphql/',
  },
  storage: {
    storage_endpoint: 'https://storage.test.elecate.net/academy',
    academy_bucket: 'academy-bucket',
    image_viewer: 'https://elecatetestst01.blob.core.windows.net',
    video_viewer: 'https://video.academy.test.elecate.net',
  },
  graphqlServerName: {
    academy_name_endpoint: 'AcademyGraphQLEndpoint',
  },
};
